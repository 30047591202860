'use strict';

var $ = require('jquery');

var toggleTabs = function(_tabs, _burger){
	if (_tabs.is( ":hidden" )) {
		// attention au décalage de 5px du au fait 
		// de la marge entre le bouton et le début du menu
		var _top = _burger.offset().top - 5;
		_tabs.css('top', _top + 'px');
		_tabs.show();
		_burger.attr('aria-expanded', true);
		_burger.attr('aria-label', 'Masquer le menu');
		_burger.attr('title', 'Masquer le menu');
	} else {
		_tabs.hide();
		_burger.attr('aria-expanded', false);
		_burger.attr('aria-label', 'Afficher le menu');
		_burger.attr('title', 'Afficher le menu');
	}
}


$( document ).ready(function() {

	var _burger = $('#burger');
	var _tabs = $('#products-tabs');
	var _links = $('#products-tabs a');

	if (_burger && _tabs) {

		_burger.attr('aria-expanded', false);

		_burger.click(function() {
			toggleTabs(_tabs, _burger);
		});

		_links.click(function() {
			toggleTabs(_tabs, _burger);
		});

	}

});