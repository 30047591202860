'use strict';

var $ = require('jquery');

$( document ).ready(function() {

  $('#products-tabs li a').each(function( index ) {

    var _div  = $(this).attr('aria-controls');
    if ($.trim($(this).text()) == $.trim($('#' + _div + ' > *').text())) {
      $(this).parent().remove();
      $('#' + _div + ' > *').remove();
    }

  });

});
